
import Vue from 'vue'
import { readKwikDashboardMiniDrawer } from 'kwik-vue/store/main/getters'
import NavigationDrawerEntry from '@/components/navigation/menu/NavigationDrawerEntry.vue'
// import NavigationDrawerSubEntry from '@/components/navigation/menu/NavigationDrawerSubEntry.vue'
import { RouteNames } from '@/router/names'
import { readIsInbound, readIsManager, readIsOutbound, readIsProduction } from '@/modules/match/store/getters'

export default Vue.extend({
  name: 'NavigationDrawerContent',
  components: { NavigationDrawerEntry },
  data () {
    return {
      openAdmin: false,
      openTenant: false
    }
  },
  computed: {
    menuItems (): any[] {
      const menu = []
      menu.push({
        key: 'dashboard',
        to: { name: RouteNames.FF_DASHBOARD },
        title: this.$t('navigation.home'),
        icon: 'mdi-home'
      })

      return menu
    },
    miniDrawer (): boolean {
      return readKwikDashboardMiniDrawer(this.$store)
    },
    isInbound (): boolean {
      return readIsInbound(this.$store)
    },
    isManager (): boolean {
      return readIsManager(this.$store)
    },
    isOutbound (): boolean {
      return readIsOutbound(this.$store)
    },
    isProduction (): boolean {
      return readIsProduction(this.$store)
    }
  },
  methods: {
    changeOpenAdmin (e: any) {
      console.log(e)
    }
  },
  watch: {
    readHasCustomersManagementRead: {
      async handler (val) {
        console.log('customer ' + val)
      }
    }
  }
})
