
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikDialog, KwikTableAction } from 'kwik-vue'
import { ITrackingNote, ITrackingNoteCreate, ITrackingNotesGetInput } from '../types'
import { dispatchCreateTrackingNotes, dispatchDeleteTrackingNotes, dispatchGetTrackingNotesCurrentPage } from '../store/actions'
import { readTrackingNotesCount, readTrackingNotesCurrentPage } from '../store/getters'
import { clockToFormatDate } from '@/utils/clockManager'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'

export default Vue.extend({
  name: 'FFTrackingNotesTable',
  components: { KwikDialog, FiltersBar, KwikTableAction },
  data () {
    return {
      options: {} as any,
      loading: true,
      showInsert: false,
      insertBatchInput: '',
      insertBatchOutput: '',
      insertNotes: '',
      filters: [] as ActiveFilterItem[],
      showFilters: false,
      showDelete: false,
      selectedNotes: null as ITrackingNote | null
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Data creazione',
          sortable: true,
          value: 'clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lotto in ingresso',
          sortable: true,
          value: 'batch_input',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lotto generato',
          sortable: true,
          value: 'batch_output',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Note',
          sortable: true,
          value: 'notes',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    trackingNotesItems () {
      return readTrackingNotesCurrentPage(this.$store)
    },
    total () {
      return readTrackingNotesCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'batch_input',
          label: 'Lotto input',
          field: 'batch_input',
          type: FilterTypes.STRING
        },
        {
          key: 'batch_output',
          label: 'Lotto output',
          field: 'batch_output',
          type: FilterTypes.STRING
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let batchInput = null
      let batchOutput = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'batch_input':
              batchInput = filter.value
              break
            case 'batch_output':
              batchOutput = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: ITrackingNotesGetInput = {
        paging: paging,
        sorting: sorting,
        batch_input: batchInput,
        batch_output: batchOutput,
        notes: null
      }
      await dispatchGetTrackingNotesCurrentPage(this.$store, payload)
      this.loading = false
    },
    clearInsertData () {
      this.insertBatchInput = ''
      this.insertBatchOutput = ''
      this.insertNotes = ''
    },
    startInsertNotes () {
      this.showInsert = true
    },
    async doInsertNotes () {
      const form: any = this.$refs.form
      if (!form.validate()) return
      const payload: ITrackingNoteCreate = {
        batch_input: this.insertBatchInput,
        batch_output: this.insertBatchOutput,
        notes: this.insertNotes
      }
      await dispatchCreateTrackingNotes(this.$store, payload)
      this.clearInsertData()
      this.loadPage()
      this.showInsert = false
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    startDeleteNote (notes: ITrackingNote) {
      this.showDelete = true
      this.selectedNotes = notes
    },
    async deleteNote () {
      if (this.selectedNotes === null) return
      await dispatchDeleteTrackingNotes(this.$store, this.selectedNotes.id)
      this.selectedNotes = null
      this.loadPage()
      this.showDelete = false
    }
  },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  }
})
