
import Vue from 'vue'

import { readKwikDashboardMiniDrawer, readKwikDashboardShowDrawer } from 'kwik-vue/store/main/getters'
import TopBarContent from '@/components/navigation/TopBarContent.vue'
import NavigationDrawerContainer from '@/components/navigation/menu/NavigationDrawerContainer.vue'
import { dispatchKwikGetMe } from 'kwik-vue/store/currentUser/actions'
import { commitKwikSetDashboardShowDrawer } from 'kwik-vue/store/main/mutations'
import { readKwikMe } from 'kwik-vue/store/currentUser/getters'
import { dispatchSetAppRoles } from '@/store/auth/actions'
import { dispatchGetCurrentMatch } from '@/modules/match/store/actions'
import { IUserWithPermissionsAndRoles } from 'kwik-vue'

export default Vue.extend({
  name: 'Main',
  components: { NavigationDrawerContainer, TopBarContent },
  data () {
    return {
      drawer: true
    }
  },
  computed: {
    miniDrawer (): boolean {
      return readKwikDashboardMiniDrawer(this.$store)
    },
    showDrawer (): boolean {
      return readKwikDashboardShowDrawer(this.$store)
    },
    me (): IUserWithPermissionsAndRoles | null {
      return readKwikMe(this.$store)
    }
  },
  methods: {
    setDrawer (val: boolean) {
      if (readKwikDashboardShowDrawer(this.$store) !== val) {
        commitKwikSetDashboardShowDrawer(this.$store, val)
      }
    }
  },
  watch: {
    showDrawer (val) {
      this.drawer = val
    },
    me (val) {
      dispatchSetAppRoles(this.$store, val)
    }
  },
  async beforeCreate () {
    await dispatchKwikGetMe(this.$store)
    await dispatchGetCurrentMatch(this.$store)
  }
})
