
import Vue from 'vue'
import NavigationDrawerContent from '@/components/navigation/menu/NavigationDrawerContent.vue'
import { readKwikMe } from 'kwik-vue/store/currentUser/getters'
import { IUserWithPermissionsAndRoles } from 'kwik-vue/interfaces/index'

export default Vue.extend({
  name: 'NavigationDrawerContainer',
  components: { NavigationDrawerContent },
  data () {
    return {
      showProjectSelector: false
    }
  },
  computed: {
    me (): IUserWithPermissionsAndRoles | null {
      return readKwikMe(this.$store)
    }
  },
  watch: {
    me: {
      handler (value) {
        if (!value) return
        let foundSuitableRole = false
        for (const role of value.roles) {
          if (role.name !== 'admin' && role.name !== 'settings_manager') foundSuitableRole = true
        }
        this.showProjectSelector = foundSuitableRole
      }
    }
  }
})
