
import Vue, { PropType } from 'vue'
import { IMatch, IMaterial } from '@/shared/types/match'
import { readCurrentMatch } from '@/modules/match/store/getters'

export default Vue.extend({
  name: 'MaterialLookupSelector',
  props: {
    initSelected: {
      type: Object as PropType<IMaterial>
    },
    editingEnabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    label: {
      type: String,
      default () {
        return 'Materiale'
      }
    },
    placeholder: {
      type: String,
      default () {
        return 'Seleziona il materiale'
      }
    },
    mode: {
      type: String,
      default () {
        return 'all'
      }
    },
    returnLabel: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      loadingError: false,
      items: [] as IMaterial[],
      selectedItem: null as IMaterial | null
    }
  },
  computed: {
    selectedCustomerFullName (): string {
      return ('ok')
    }
  },
  methods: {
    formatItem (item: IMaterial): string {
      return item.name
    },
    loadProducts (match: IMatch | null) {
      if (!match) return null
      // TODO matteo usa il primo universo non quello selezionato
      return match.activeUniverses[0].materials
    }
  },
  watch: {
    selectedItem: {
      handler (val) {
        if (val) {
          if (!this.returnLabel) this.$emit('selection', val.id)
          else this.$emit('selection', this.selectedItem)
        }
      }
    },
    initSelected: {
      handler (val) {
        this.selectedItem = val
      }
    }
  },
  async mounted () {
    const match = readCurrentMatch(this.$store)
    const items = this.loadProducts(match)
    if (items) {
      this.items = items
    }
    if (this.initSelected) {
      this.selectedItem = this.initSelected
    }
  }
})
