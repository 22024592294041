
import Vue from 'vue'
import ProfileMenu from '@/components/navigation/ProfileMenu.vue'
import { commitKwikAddNotification, commitKwikSetDashboardMiniDrawer, commitKwikSetDashboardShowDrawer } from 'kwik-vue/store/main/mutations'
import { readKwikDashboardMiniDrawer, readKwikDashboardShowDrawer, readKwikSelectedTheme } from 'kwik-vue/store/main/getters'
import { dispatchKwikSwitchTheme } from 'kwik-vue/store/main/actions'
import { dispatchKwikIsImpersonating, dispatchKwikStopImpersonate } from 'kwik-vue/store/currentUser/actions'
import { readKwikIsImpersonating } from 'kwik-vue/store/currentUser/getters'
import { ThemeSelector } from '@/interfaces/theme'
import FFTrackingNotesTable from '@/modules/tracking_notes/components/FFTrackingNotesTable.vue'
import { KwikDialog } from 'kwik-vue'
import FFNotificationsTable from '@/modules/notifications/components/FFNotificationsTable.vue'
import { commitSetClock } from '@/modules/match/store/mutations'
import { readClock, readCurrentUserUniverse } from '@/modules/match/store/getters'
import { clockToFormatDate } from '@/utils/clockManager'
import { IUserUniverse } from '@/shared/types/match'

export default Vue.extend({
  name: 'TopBarContent',
  components: { ProfileMenu, FFTrackingNotesTable, KwikDialog, FFNotificationsTable },
  data () {
    return {
      FFNotificationsTablehemeLight: ThemeSelector.LIGHT,
      themeDark: ThemeSelector.DARK,
      showTrackingNotes: false,
      showNotifications: false,
      currentDateTime: '',
      companyName: ''
    }
  },
  computed: {
    selectedTheme () {
      return readKwikSelectedTheme(this.$store)
    },
    isImpersonating () {
      return readKwikIsImpersonating(this.$store)
    },
    currentClock () {
      return readClock(this.$store)
    },
    currentUserUniverse () {
      return readCurrentUserUniverse(this.$store)
    }
  },
  methods: {
    openTrackingNotes () {
      this.showTrackingNotes = true
    },
    switchShowDrawer () {
      commitKwikSetDashboardShowDrawer(
        this.$store,
        !readKwikDashboardShowDrawer(this.$store)
      )
    },
    switchMiniDrawer () {
      commitKwikSetDashboardMiniDrawer(
        this.$store,
        !readKwikDashboardMiniDrawer(this.$store)
      )
    },
    switchDrawer () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': this.switchShowDrawer(); break
        case 'sm': this.switchShowDrawer(); break
        case 'md': this.switchShowDrawer(); break
        case 'lg': this.switchMiniDrawer(); break
        case 'xl': this.switchMiniDrawer(); break
      }
    },
    switchTheme () {
      if (readKwikSelectedTheme(this.$store) === ThemeSelector.LIGHT) {
        dispatchKwikSwitchTheme(this.$store, ThemeSelector.DARK)
        this.$vuetify.theme.dark = true
      } else {
        dispatchKwikSwitchTheme(this.$store, ThemeSelector.LIGHT)
        this.$vuetify.theme.dark = false
      }
    },
    stopImpersonating () {
      dispatchKwikStopImpersonate(this.$store)
    },
    openHandbook () {
      // window.open('https://handbook.xto.kheperer.it/', '_blank')
    }
  },
  created () {
    dispatchKwikIsImpersonating(this.$store)
  },
  mounted () {
    this.$socket.client.emit('join', { universe_id: 1, company_id: 1 })
  },
  destroyed () {
    this.$socket.client.emit('leave', { universe_id: 1, company_id: 1 })
  },
  watch: {
    currentClock (clock: number) {
      this.currentDateTime = clockToFormatDate(clock, 'shortDateTime')
    },
    currentUserUniverse: {
      async handler (universe: IUserUniverse | null) {
        if (universe) {
          this.companyName = universe.company.business_name
        } else {
          this.companyName = 'COMPANY_NAME'
        }
      },
      deep: true
    }
  },
  sockets: {
    clock_update: function (data) {
      commitSetClock(this.$store, data.clock)
    },
    notifications: function (data) {
      data.notifications.forEach((notification: any) => {
        const companyId = this.$store.state.matchModule.currentMatch.activeUniverses[0].company.id
        // debugger
        if (notification.company_id === null || notification.company_id === companyId) {
          commitKwikAddNotification(this.$store, {
            content: notification.message,
            color: 'success'
          })
          // this.notifications.push(notification)
          // if (notification.is_outbound) {
          //   this.$notification.success({
          //     message: notification.message,
          //   })
          //   const requestId = this.$store.state.user.lastRequestId + 1
          //   console.log(requestId)
          //   // this.$store.dispatch('setLastCustomerRequest', requestId)
          // }
        }
      })
    }
  }
})
