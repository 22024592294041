
import Vue, { PropType } from 'vue'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import MaterialLookupSelector from '@/components/lookups/MaterialLookupSelector.vue'
import RawMaterialLookupSelector from '@/components/lookups/RawMaterialLookupSelector.vue'
import SupplierLookupSelector from '@/components/lookups/SupplierLookupSelector.vue'
import CustomerLookupSelector from '@/components/lookups/CustomerLookupSelector.vue'
import OfferingStateLookupSelector from '@/components/lookups/OfferingStateLookupSelector.vue'
import CustomerTransactionStateLookupSelector from '@/components/lookups/CustomerTransactionStateLookupSelector.vue'
import SupplierTransactionStateLookupSelector from '@/components/lookups/SupplierTransactionStateLookupSelector.vue'
import ProductionStateLookupSelector from '@/components/lookups/ProductionStateLookupSelector.vue'
import MachineLookupSelector from '@/components/lookups/MachineLookupSelector.vue'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import { ActiveFilterItem, DateNullable, FilterSavePayload, IChip, IChips, KwikDialog, KwikTableAction, KwikDatePicker } from 'kwik-vue'
import { IMachine, IMaterial } from '@/shared/types/match'
import { ICustomer } from '@/modules/offering/types'
import { ISupplier } from '@/modules/supplier_transaction/types'
import { ILookupBase } from '@/components/filtersBar/lookupType'

export default Vue.extend({
  name: 'FiltersBar',
  components: {
    KwikTableAction,
    KwikDialog,
    KwikDatePicker,
    MaterialLookupSelector,
    RawMaterialLookupSelector,
    SupplierLookupSelector,
    CustomerLookupSelector,
    MachineLookupSelector,
    OfferingStateLookupSelector,
    CustomerTransactionStateLookupSelector,
    SupplierTransactionStateLookupSelector,
    ProductionStateLookupSelector
  },
  props: {
    filterOptions: {
      type: Array as PropType<FilterOption[]>,
      required: true,
      default () {
        return [] as FilterOption[]
      }
    },
    pageName: {
      type: String,
      default () {
        return ''
      }
    },
    initFilters: {
      type: Array as PropType<ActiveFilterItem[]>,
      required: false,
      default () {
        return [] as ActiveFilterItem[]
      }
    }
  },
  data () {
    return {
      activeFilters: [] as ActiveFilterItem[],
      showFilterDialog: false,
      currentFilter: null as FilterOption | null,
      filterValue: null as any,
      filterDisplayValue: '',
      filterValueBoolean: null as boolean | null,
      filterValueMultipleSelection: [],
      filterValueDateIntervalStart: null as DateNullable,
      filterValueDateIntervalEnd: null as DateNullable,
      selectSingleDate: false,
      showFilterList: false,
      savedFilters: [] as FilterSavePayload[],
      showSavedFilterSelectDialog: false,
      showInsertFilterNameDialog: false,
      filterToSaveName: '',
      filterToUpdate: null as FilterSavePayload | null,
      showChangeFilterNameDialog: false,
      filterNewName: ''
    }
  },
  computed: {
    chips (): IChips {
      const chipsToReturn: IChips = {
        chips: [] as IChip[]
      }
      const dateChip: IChips = {
        chips: [] as IChip[]
      }
      for (const filter of this.activeFilters) {
        if (filter.field === 'start_due_date' || filter.field === 'end_due_date') {
          const c: IChip = {
            label: [this.$t(`components.chipLabel_${filter.field}`).toString()],
            value: [filter.valueToShow ? filter.valueToShow : filter.value],
            field: filter.field,
            fieldsToRemove: [],
            filterSingleDate: filter.selectSingleDate ? filter.selectSingleDate : false
          }
          dateChip.chips.push(c)
        } else {
          const c: IChip = {
            label: [this.$t(`components.chipLabel_${filter.field}`).toString()],
            value: [filter.valueToShow ? filter.valueToShow : filter.value],
            field: filter.field,
            fieldsToRemove: []
          }
          if (chipsToReturn.chips) chipsToReturn.chips.push(c)
        }
      }
      if (dateChip.chips.length === 1) {
        const chipTemp = { ...dateChip.chips[0] }
        const date = new Date(chipTemp.value[0])
        if (chipTemp.field === 'end_due_date') {
          date.setDate(date.getDate() - 1)
        }
        chipTemp.value = [date.toLocaleDateString()]
        chipsToReturn.chips.push(chipTemp)
      } else if (dateChip.chips.length === 2) {
        const firstDate = new Date(dateChip.chips[0].value[0])
        const secondDate = new Date(dateChip.chips[1].value[0])
        if (dateChip.chips[0].filterSingleDate === true) {
          const newDateChip: IChip = {
            label: [this.$t('components.filterSingleDateChipLabel').toString()],
            value: [firstDate.toLocaleDateString()],
            field: dateChip.chips[0].field,
            fieldsToRemove: [dateChip.chips[0].field, dateChip.chips[1].field]
          }
          chipsToReturn.chips.push(newDateChip)
        } else {
          secondDate.setDate(secondDate.getDate() - 1)
          const newDateChip: IChip = {
            label: [this.$t('components.filterDateIntervalChipLabelFirst').toString(), this.$t('components.filterDateIntervalChipLabelSecond').toString()],
            value: [firstDate.toLocaleDateString(), secondDate.toLocaleDateString()],
            field: dateChip.chips[0].field,
            fieldsToRemove: [dateChip.chips[0].field, dateChip.chips[1].field]
          }
          chipsToReturn.chips.push(newDateChip)
        }
      }
      return chipsToReturn
    },
    menuOptions (): FilterOption[] {
      const fields = [] as string[]
      for (const filter of this.activeFilters) fields.push(filter.field)
      return this.filterOptions.filter(f => !fields.includes(f.field))
    }
  },
  methods: {
    handleMaterialWithLabel (material: IMaterial) {
      this.filterValue = material.id
      this.filterDisplayValue = material.name
    },
    handleCustomerWithLabel (customer: ICustomer) {
      this.filterValue = customer.id
      this.filterDisplayValue = customer.business_name
    },
    handleMachineWithLabel (machine: IMachine) {
      this.filterValue = machine.id
      this.filterDisplayValue = machine.name
    },
    handleSupplierWithLabel (supplier: ISupplier) {
      this.filterValue = supplier.id
      this.filterDisplayValue = supplier.business_name
    },
    handleOfferingStateWithLabel (offeringState: ILookupBase) {
      this.filterValue = offeringState.value
      this.filterDisplayValue = this.$t('offering.' + offeringState.value) as string
    },
    handleProductionStateWithLabel (productionState: ILookupBase) {
      this.filterValue = productionState.value
      this.filterDisplayValue = this.$t('production.' + productionState.value) as string
    },
    handleCustomerTransactionStateWithLabel (customerTransaction: ILookupBase) {
      this.filterValue = customerTransaction.value
      this.filterDisplayValue = this.$t('customerTransaction.' + customerTransaction.value) as string
    },
    handleSupplierTransactionStateWithLabel (supplierTransaction: ILookupBase) {
      this.filterValue = supplierTransaction.value
      this.filterDisplayValue = this.$t('supplierTransaction.' + supplierTransaction.value) as string
    },
    openFilterDialog (filterOption: FilterOption) {
      this.currentFilter = filterOption
    },
    closeFilterDialog () {
      this.showFilterDialog = false
      this.showFilterList = false
      this.filterValue = null
      this.filterDisplayValue = ''
      this.filterValueBoolean = null
      this.filterValueMultipleSelection = []
      this.filterValueDateIntervalStart = null
      this.filterValueDateIntervalEnd = null
    },
    addFilter () {
      if (!this.currentFilter || !this.filterValue) {
        this.showFilterDialog = false
        return
      }
      const filter: ActiveFilterItem = {
        label: this.currentFilter.label + ': ' + (this.filterDisplayValue !== '' ? this.filterDisplayValue : this.filterValue),
        value: this.filterValue,
        field: this.currentFilter.field,
        valueToShow: this.filterDisplayValue
      }
      if (this.currentFilter.type === FilterTypes.STRING) filter.isStringField = true
      this.activeFilters.push(filter)
      this.currentFilter = null
      this.filterValue = null
      this.filterDisplayValue = ''
      this.filterValueMultipleSelection = []
      this.closeFilterDialog()
      this.$emit('filter', this.activeFilters)
    },
    addFilterBoolean () {
      if (!this.currentFilter) return
      if (this.filterValueBoolean == null) {
        this.filterValueBoolean = false
      }
      const filter: ActiveFilterItem = {
        label: this.currentFilter.label + ': ' + this.filterValueBoolean,
        value: this.filterValueBoolean,
        field: this.currentFilter.field
      }
      this.activeFilters.push(filter)
      this.currentFilter = null
      this.filterValueBoolean = null
      this.filterValueMultipleSelection = []
      this.showFilterDialog = false
      this.showFilterList = false
      this.$emit('filter', this.activeFilters)
    },
    addFilterDateInterval (startField: string, endField: string) {
      if (!this.filterValueDateIntervalStart && !this.filterValueDateIntervalEnd) return
      if (this.filterValueDateIntervalStart) {
        const filter: ActiveFilterItem = {
          label: this.filterValueDateIntervalStart.toLocaleDateString(),
          value: this.filterValueDateIntervalStart,
          field: startField,
          selectSingleDate: this.selectSingleDate
        }
        this.activeFilters.push(filter)
        if (this.selectSingleDate) {
          const newEndDate = new Date(this.filterValueDateIntervalStart)
          newEndDate.setDate(newEndDate.getDate() + 1)
          const filter: ActiveFilterItem = {
            label: newEndDate.toLocaleDateString(),
            value: newEndDate,
            field: endField,
            selectSingleDate: true
          }
          this.activeFilters.push(filter)
        }
      }
      if (this.filterValueDateIntervalEnd && !this.selectSingleDate) {
        const newEndDate = new Date(this.filterValueDateIntervalEnd.setDate(this.filterValueDateIntervalEnd.getDate() + 1))
        const filter: ActiveFilterItem = {
          label: this.filterValueDateIntervalEnd.toLocaleDateString(),
          value: newEndDate,
          field: endField
        }
        this.activeFilters.push(filter)
      }
      this.currentFilter = null
      this.filterValueDateIntervalStart = null
      this.filterValueDateIntervalEnd = null
      this.showFilterDialog = false
      this.showFilterList = false
      this.$emit('filter', this.activeFilters)
    },
    addFilterMultipleSelection () {
      if (!this.currentFilter || this.filterValueMultipleSelection.length === 0) return
      const filter: ActiveFilterItem = {
        label: this.currentFilter.label + ': ' + this.filterValueMultipleSelection,
        value: this.filterValueMultipleSelection,
        field: this.currentFilter.field
      }
      this.activeFilters.push(filter)
      this.currentFilter = null
      this.filterValueMultipleSelection = []
      this.showFilterDialog = false
      this.showFilterList = false
      this.$emit('filter', this.activeFilters)
    },
    removeFiltersByFields (fields: string[]) {
      this.activeFilters = this.activeFilters.filter(t => !fields.includes(t.field))
      this.$emit('filter', this.activeFilters)
    },
    setFilters (filter: FilterSavePayload) {
      this.activeFilters = filter.value
      this.$emit('filter', this.activeFilters)
      this.showSavedFilterSelectDialog = false
    },
    handleEditFilter (filter: FilterSavePayload, event: Event) {
      event.stopPropagation()
      this.filterNewName = filter.name
      this.showChangeFilterNameDialog = true
      this.filterToUpdate = filter
    }
  },
  async mounted () {
    if (this.initFilters) {
      this.activeFilters = this.initFilters
    }
  },
  watch: {
    chips: {
      // TODO: togliere questo watch, serve solo per potere stampare i chips
      async handler () {
        //
      }
    }
  }
})
