
import Vue, { PropType } from 'vue'
import { IMatch, ISupplier } from '@/shared/types/match'
import { readCurrentMatch } from '@/modules/match/store/getters'

export default Vue.extend({
  name: 'SupplierLookupSelector',
  props: {
    initSelected: {
      type: Object as PropType<ISupplier>
    },
    editingEnabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    label: {
      type: String,
      default () {
        return 'Fornitore'
      }
    },
    placeholder: {
      type: String,
      default () {
        return 'Seleziona il fornitore'
      }
    },
    mode: {
      type: String,
      default () {
        return 'all'
      }
    },
    returnLabel: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      loadingError: false,
      items: [] as ISupplier[],
      selectedItem: null as ISupplier | null
    }
  },
  computed: {
    selectedCustomerFullName (): string {
      return ('ok')
    },
    match (): IMatch | null {
      return readCurrentMatch(this.$store)
    }
  },
  methods: {
    formatItem (item: ISupplier): string {
      return item.business_name
    },
    loadItems (match: IMatch | null) {
      if (!match) return null
      // TODO usa il primo universo non quello selezionato
      const items = match.activeUniverses[0].universe.suppliers
      if (items) {
        this.items = items
      }
      if (this.initSelected) {
        this.selectedItem = this.initSelected
      }
    }
  },
  async mounted () {
    const match = readCurrentMatch(this.$store)
    this.loadItems(match)
  },
  watch: {
    selectedItem: {
      handler (val) {
        if (val) {
          if (!this.returnLabel) this.$emit('selection', val.id)
          else this.$emit('selection', this.selectedItem)
        }
      }
    },
    initSelected: {
      handler (val) {
        this.selectedItem = val
      }
    },
    match: {
      handler (val: IMatch | null) {
        if (!val) return
        this.loadItems(val)
      }
    }
  }
})
