
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikTooltipButtonIcon, KwikDialog } from 'kwik-vue'
import { ISupplierTransaction, ISupplierTransactionGetInput } from '../types'
import { dispatchAcceptSupplierTransaction, dispatchGetSupplierTransactionsCurrentPage } from '../store/actions'
import { readSupplierTransactionsCount, readSupplierTransactionsCurrentPage } from '../store/getters'
import { RouteNames } from '@/router/names'
import { clockToFormatDate } from '@/utils/clockManager'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'

export default Vue.extend({
  name: 'FFSupplierTransactions',
  components: {
    KwikMediumContainer,
    KwikPageToolbar,
    KwikTableAction,
    KwikTooltipButtonIcon,
    FiltersBar,
    KwikDialog
  },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      showAccepting: false,
      acceptingId: 0,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Transazioni fornitori', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Fornitore',
          sortable: false,
          value: 'supplier.business_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di acquisto',
          sortable: true,
          value: 'creation_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Data di consegna',
          sortable: true,
          value: 'public_lead_time',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Totale',
          sortable: true,
          value: 'grand_total',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Stato',
          sortable: true,
          value: 'status',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Causale',
          sortable: true,
          value: 'rejection_cause',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    supplierTransactionsItems () {
      return readSupplierTransactionsCurrentPage(this.$store)
    },
    total () {
      return readSupplierTransactionsCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'supplier_id',
          label: 'Fornitore',
          field: 'supplier_id',
          type: FilterTypes.SUPPLIER
        },
        {
          key: 'status',
          label: 'Stato',
          field: 'status',
          type: FilterTypes.SUPPLIER_TRANSACTION_STATE
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let supplierId = null
      let status = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'supplier_id':
              supplierId = filter.value
              break
            case 'status':
              status = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: ISupplierTransactionGetInput = {
        paging: paging,
        sorting: sorting,
        supplier_id: supplierId,
        status: status
      }
      await dispatchGetSupplierTransactionsCurrentPage(this.$store, payload)
      this.loading = false
    },
    async openDetail (id: string) {
      await this.$router.push(
        {
          name: RouteNames.FF_SUPPLIER_TRANSACTION_ROWS,
          params: { supplier_transaction_id: id }
        }
      )
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    async buy () {
      await this.$router.push(
        {
          name: RouteNames.FF_NEW_SUPPLIER_TRANSACTIONS
        }
      )
    },
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    startAccepting (item: ISupplierTransaction) {
      this.acceptingId = item.id
      this.showAccepting = true
    },
    async accept () {
      await dispatchAcceptSupplierTransaction(this.$store, this.acceptingId)
      this.acceptingId = 0
      this.showAccepting = false
      this.loadPage()
    }
  }
})
