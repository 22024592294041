
import Vue, { PropType } from 'vue'
import { Route } from 'vue-router'

export default Vue.extend({
  name: 'NavigationDrawerEntry',
  props: {
    to: {
      type: Object as PropType<Route>
    },
    title: String,
    icon: String,
    show: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
})
