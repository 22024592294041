
import Vue from 'vue'
import { dispatchKwikSwitchTheme, dispatchKwikUserLogOut } from 'kwik-vue/store/main/actions'
import { readKwikSelectedTheme } from 'kwik-vue/store/main/getters'
import { saveCurrentLocale } from '@/utils/localStorage'
import { readKwikMe } from 'kwik-vue/store/currentUser/getters'
import { ThemeSelector } from '@/interfaces/theme'
import { IRouteLogoutPayload } from 'kwik-vue'

export default Vue.extend({
  name: 'ProfileMenu',
  data () {
    return {
      ThemeSelector: ThemeSelector
    }
  },
  computed: {
    me () {
      return readKwikMe(this.$store)
    },
    profileLabel (): string {
      if (this.me) {
        if (this.me.email) return this.me.email
      }
      return ''
    },
    avatar (): string {
      if (this.me) {
        if (this.me.email) return this.me.email.substr(0, 2)
      }
      return ''
    },
    selectedTheme () {
      return readKwikSelectedTheme(this.$store)
    }
  },
  methods: {
    async logout (): Promise<void> {
      const logoutPayload: IRouteLogoutPayload = {
        routeTo: '/login',
        routeExcludeNavigation: []
      }
      await dispatchKwikUserLogOut(this.$store, logoutPayload)
    },
    switchTheme () {
      if (readKwikSelectedTheme(this.$store) === ThemeSelector.LIGHT) {
        dispatchKwikSwitchTheme(this.$store, ThemeSelector.DARK)
        this.$vuetify.theme.dark = true
      } else {
        dispatchKwikSwitchTheme(this.$store, ThemeSelector.LIGHT)
        this.$vuetify.theme.dark = false
      }
    },
    translate (locale: string) {
      this.$i18n.locale = locale
      saveCurrentLocale(locale)
    }
  }
})
