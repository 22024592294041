import { render, staticRenderFns } from "./FFSupplierTransactions.vue?vue&type=template&id=530026fc&scoped=true"
import script from "./FFSupplierTransactions.vue?vue&type=script&lang=ts"
export * from "./FFSupplierTransactions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530026fc",
  null
  
)

export default component.exports