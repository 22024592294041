import { render, staticRenderFns } from "./NavigationDrawerContainer.vue?vue&type=template&id=38c5ab14&scoped=true"
import script from "./NavigationDrawerContainer.vue?vue&type=script&lang=ts"
export * from "./NavigationDrawerContainer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c5ab14",
  null
  
)

export default component.exports